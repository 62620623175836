import axios from 'axios';

/**
 * @description 마이사운드 리스트
 * @param {*} userId
 * @param {*} spaceId
 * @param {*} limit
 * @param {*} offset
 * @returns Promise
 */
const getListOfMySoundApi = async (userId, spaceId, limit = 999, offset = 0) => {
  return await axios.post(`/api/mysound/getListOfMySound`, {
    userId,
    spaceId,
    limit,
    offset
  });
};

/**
 * @description 마이사운드 삭제
 * @param {*} userId
 * @param {*} brandMusicId
 * @param {*} mySoundDetail
 * @returns Promise
 */
const deleteMySoundApi = async (userId, brandMusicId, mySoundDetail = { brandSpaceMusicId: -1 }) => {
  return await axios.post(`/api/mysound/deleteMySound`, {
    userId,
    brandMusicId,
    mySoundDetail
  });
};

/**
 * @description 파일 업로드 Binary
 * @param {*} formData
 * @returns Promise
 */
const uploadChosenFileApi = async (formData = new FormData()) => {
  return await axios.post(`/api/mysound/uploadChosenVoiceRecFile`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

/**
 * @description 파일명 입력
 * @param {*} userId
 * @param {*} spaceId
 * @param {*} soundTitle
 * @param {*} fileOnServer
 * @param {*} playtime
 * @returns Promise
 */
const registMySoundByFileApi = async (
  userId = null,
  spaceId = null,
  soundTitle = null,
  tempNo = null,
  playtime = 0
) => {
  return await axios.post(`/api/mysound/registMySoundByVoiceRecFile`, {
    userId,
    spaceId,
    soundTitle,
    tempNo,
    playtime
  });
};

export { getListOfMySoundApi, deleteMySoundApi, uploadChosenFileApi, registMySoundByFileApi };
